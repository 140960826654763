import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle<any>`

#root {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.background}
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  transition-duration: 300ms;
}

::-webkit-scrollbar-track {
  background-color: ${({ theme }) => theme.background};
  border-radius: 50px;
  transition-duration: 300ms;
}

::-webkit-scrollbar-thumb {
  background-color: ${({ theme }) => theme.gray100};
  border-radius: 50px;
  transition-duration: 300ms;

}
::-webkit-scrollbar-thumb:hover {
  background-color: ${({ theme }) => theme.gray400};
  transition-duration: 300ms;
}


`;
