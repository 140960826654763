import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from './assets/Theme';
import { GlobalStyle } from './assets/Theme/GlobalStyle';
import { useLogin } from './hook';
import { Routes } from './routes';

function App() {
  const { restoreSession } = useLogin();
  useEffect(() => {
    restoreSession();
  }, [restoreSession]);
  return (
    <ThemeProvider theme={theme['light']}>
      <GlobalStyle />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
