import { ReactElement } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex: 0.8;
  justify-content: center;
  align-items: center;
  & button {
    margin-top: 20px;
  }
`;

export const Image = styled.img`
  width: 80%;
`;

export const Title = styled.h1.attrs((props: ReactElement<HTMLTitleElement>) => {})`
  color: #2a2a2a;
  font-size: 36px;
  font-weight: 700;
  margin-top: 2em;
  margin-bottom: 1em;
`;

export const Label = styled.label.attrs((props: ReactElement<HTMLTitleElement>) => {})`
  color: #2a2a2a;
  font-size: 16px;
  font-weight: 600;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
  width: 380px;
`;
