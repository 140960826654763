import { Display } from '../../components';
import * as S from './styles';
import chatMetrics from '../../mocks/chat-info.json';

export const TimeScreen = () => {
  return (
    <S.Container>
      <S.WrapperHeader>
        <S.SectionHeader>
          <S.Title>totais atendimentos</S.Title>
          <Display.Container>
            <Display title="atendimento" value={chatMetrics.info[0].chatCount} />
            <Display title="em aberto" value={chatMetrics.open[0].amount} color="primary" />
            <Display title="Finalizados" value={chatMetrics.closed[0].amount} />
          </Display.Container>
        </S.SectionHeader>
        <S.SectionHeader>
          <S.Title>totais atendimentos</S.Title>
          <Display.Container>
            <Display title="Mensagens" value={chatMetrics.info[0].msgCount} force={3} />
            <Display title="media por chat" value={chatMetrics.info[0].avgMsgPerChat} color="blue400" decimal={1} />
          </Display.Container>
        </S.SectionHeader>
      </S.WrapperHeader>
    </S.Container>
  );
};
