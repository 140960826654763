import { VscLoading } from 'react-icons/vsc';
import styled, { css } from 'styled-components';
import { BaseButtonProps } from './types';

const BaseButton = css`
  background-color: #2a2a2a;
  border: 2px solid #2a2a2a;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  transition-duration: 250ms;
  outline: none;
  box-sizing: border-box;
  overflow: hidden;

  &::before {
    transition-duration: 250ms;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -0px;
    left: -0px;
    transform: scale(0);
    background-color: #ffffff00;
  }

  &:hover {
    cursor: pointer;
    &::before {
      content: '';
      transform: scale(1.2);
      background-color: #ffffff20;
    }
  }
`;

const isLoading = css`
  background-color: #2a2a2a90;
  border-color: #2a2a2a20;

  &:hover {
    cursor: progress;
    &::before {
      content: '';
      transform: scale(0);
    }
  }
`;

const disabled = css`
  background-color: #727f8a;
  border-color: #727f8a;
  &:hover {
    cursor: not-allowed;
  }
`;

const withIconLeft = css`
  padding-left: 0;
  svg {
    margin: 0;
    width: 17px;
    height: 17px;
    min-width: 17px;
    min-height: 17px;
  }
`;
const withIconRight = css`
  padding-right: 0;
  svg {
    margin: 0;
    width: 17px;
    height: 17px;
    min-width: 17px;
    min-height: 17px;
  }
`;
const wide = css`
  width: 100%;
`;
const width = css`
  width: ${(props: BaseButtonProps) => props.width}px;
`;

export const Container = styled.button.attrs((props: BaseButtonProps) => {})`
  ${BaseButton}
  border-radius: 8px;
  height: 40px;
  font-weight: 600;
  ${(props) => props.disabled && disabled};
  ${(props) => props.isLoading && isLoading};

  ${(props) => props.withIconLeft && withIconLeft};
  ${(props) => props.withIconRight && withIconRight};
  ${(props) => props.wide && wide};
  ${(props) => props.width && width};
`;

export const Loading = styled(VscLoading)`
  width: 16px;
  height: 16px;
  box-sizing: border-box;
`;

export const IconBox = styled.div`
  width: 17px;
  height: 17px;
  color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.7rem 0 0.7rem;
`;
