import { FaCrown } from 'react-icons/fa';
import styled from 'styled-components';
import { ALERT_CATEGORIES } from '../../domain/alert-types';

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin-top: 1rem;
  flex-direction: column;
`;

export const List = styled.ol`
  flex: 1;
  margin-top: 1rem;
`;

export const ListItem = styled.li<{ hasOnClick?: boolean }>`
  width: 100%;
  height: 70px;
  border-top: 1px solid ${({ theme }) => theme.gray100};
  display: flex;
  align-items: center;
  &:hover {
    cursor: ${props => (!!props?.hasOnClick ? 'Pointer' : 'unset')};
  }
`;

export const Link = styled.a`
  margin-left: auto;
  font-weight: 600;
  color: ${({ theme }) => theme.gray800};
  cursor: pointer;
`;

export const HeaderList = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TitleHeader = styled.h3`
  text-transform: uppercase;
  color: ${({ theme }) => theme.gray400};
  font-weight: 600;
`;

export const Image = styled.img`
  height: 50px;
  width: 50px;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 4px;
`;

export const Wrapper = styled.div`
  margin-left: 1rem;
  flex: 1;
`;

export const Title = styled.h4`
  font-weight: 600;
  color: ${({ theme }) => theme.gray800};
`;

export const Description = styled.h5`
  color: ${({ theme }) => theme.gray400};
`;

export const Quantity = styled.span`
  margin-left: auto;
  padding: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.gray800};
`;

const positions = ['#FDDA2A', '#A7ACC4', '#AF7D26'];

export const Crown = styled(FaCrown)<{ position: number }>`
  font-size: 20px;
  color: ${({ position }) => (position <= 2 ? positions[position] : 'transparent')};
`;

export const Category = styled.div<{ category: typeof ALERT_CATEGORIES[number] }>`
  height: 50px;
  width: 50px;
  background-color: ${({ theme, category }) => theme[category]};
  border-radius: 4px;
`;
