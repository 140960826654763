import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 20px;
`;

export const BackButton = styled.button`
  height: 40px;
  min-height: 40px;
  padding: 0 2rem;
  align-self: flex-start;
  border: none;
  background-color: ${({ theme }) => theme.gray50};
  font-weight: 600;
  &:hover {
    cursor: pointer;
  }
`;
