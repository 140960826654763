import { IColors } from '../../assets/Theme';
import * as S from './styles';

interface DisplayProps {
  title: string;
  value: number;
  color?: IColors;
  force?: number;
  decimal?: number;
  margin?: string
}

export const Display = ({ title, value, color, force = 1, decimal = 0, margin = '0px' }: DisplayProps) => {
  return (
    <S.Container force={force} margin={margin}>
      <S.Title>{title}</S.Title>
      <S.Content>
        <S.Value color={color} end={value} decimal="," duration={1} decimals={decimal} />
      </S.Content>
    </S.Container>
  );
};

interface DisplayContainerProps {
  children: React.ReactNode;
}

const Container = ({ children }: DisplayContainerProps) => {
  return <S.ContainerDisplay>{children}</S.ContainerDisplay>;
};

Display.Container = Container;
