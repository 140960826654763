import styled from 'styled-components';
import { BaseFormProps } from './types';

export const Container = styled.form.attrs((props: BaseFormProps) => {})`
  width: 468px;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
`;
export const Highlight1 = styled.div.attrs((props: BaseFormProps) => {})`
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 100%;
  top: -200px;
  right: -200px;
  background-color: #2a2a2a0d;
`;
export const Highlight2 = styled.div.attrs((props: BaseFormProps) => {})`
  position: absolute;
  background-color: #2a2a2a0d;
  width: 302px;
  height: 302px;
  border-radius: 100%;
  top: -151px;
  right: -151px;
`;
