import React, { forwardRef, Ref, useCallback, useState, CSSProperties } from 'react';

import * as Styled from './styles';

export interface BaseInputProps {
  value?: string;
  placeholder?: string;
  outlined?: boolean;
  rounded?: boolean;
  selected?: boolean;
  onClick?: () => void;
  onClickIcon?: () => void;
  lock?: boolean;
  leftIcon?: React.FC;
  style?: CSSProperties;
  onPressEnter?: () => void;
  onChange?: (value: string) => void;
  name?: string;
  type?: string;
  width?: number;
}

export const PrimaryInput = forwardRef(
  (
    {
      leftIcon: LeftIcon,
      placeholder,
      value,
      onChange,
      onPressEnter,
      onClickIcon,
      type,
      name,
      ...rest
    }: BaseInputProps,
    ref: Ref<BaseInputProps>
  ) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePassword = useCallback(() => {
      setShowPassword((prev) => !prev);
    }, []);

    const handlePressEnter = useCallback(
      (event: KeyboardEvent) => {
        if (event.key === 'Enter') onPressEnter?.();
      },
      [onPressEnter]
    );

    return (
      <Styled.Container {...rest}>
        {LeftIcon && (
          <Styled.IconBox onClick={onClickIcon}>
            <LeftIcon />
          </Styled.IconBox>
        )}
        <Styled.InputText
          name={name}
          placeholder={placeholder}
          type={!showPassword ? type : 'text'}
          value={value}
          onKeyDown={handlePressEnter}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange?.(event.target.value)}
          ref={ref}
        />
        {type === 'password' && (
          <Styled.IconBox onClick={handleTogglePassword}>
            {showPassword ? <Styled.Visible /> : <Styled.Hidden />}
          </Styled.IconBox>
        )}
      </Styled.Container>
    );
  }
);
