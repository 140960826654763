import React from 'react';
import * as S from './styles';
import { BaseButtonProps } from './types';

export const PrimaryButton: React.FC<BaseButtonProps> = (props) => {
  const { leftIcon: LeftIcon, rightIcon: RightIcon, label, isLoading, disabled, size, ...rest } = props;
  return (
    <S.Container
      row
      {...rest}
      isLoading={isLoading}
      disabled={disabled || isLoading}
      withIconLeft={LeftIcon || isLoading}
      withIconRight={RightIcon}
    >
      {isLoading && (
        <S.IconBox>
          <S.Loading />
        </S.IconBox>
      )}
      {!isLoading && LeftIcon && (
        <S.IconBox>
          <LeftIcon />
        </S.IconBox>
      )}
      {label}
      {RightIcon && (
        <S.IconBox>
          <RightIcon />
        </S.IconBox>
      )}
    </S.Container>
  );
};

PrimaryButton.defaultProps = {
  label: 'placeholder',
};
