import axios, { AxiosRequestConfig } from 'axios';
import { environmentNames } from '../domain';
// const APP_ENV = process.env.APP_ENV || process.env.NODE_ENV || environmentNames.DEVELOPMENT

export const backend = {
  [environmentNames.DEVELOPMENT]: {
    BFF_URL: 'https://dev-squad-bff.yellowcam.com/api/v1',
    GATEWAY_URL: 'https://dev-api.yellowcam.com/api/v1',
    ANALYTICS_URL: 'http://localhost:3000/',
  },
  [environmentNames.STAGING]: {
    BFF_URL: 'https://stg-api.yellowcam.com/rio/api/v1',
    GATEWAY_URL: 'https://stg-api.yellowcam.com/lisbon/api/v1',
    ANALYTICS_URL: 'https://analytics-api.yellowcam.com',
  },
  [environmentNames.PRODUCTION]: {
    BFF_URL: 'https://squad-bff.yellowcam.com/api/v1',
    GATEWAY_URL: 'https://api.yellowcam.com/api/v1',
    ANALYTICS_URL: 'https://analytics-api.yellowcam.com',
  },
};

const APP_ENV = (process.env.REACT_APP_ENV ?? environmentNames.PRODUCTION) as keyof typeof backend;

const selectedBackend = backend[APP_ENV];

const addAuthTokenToRequest = (storage: typeof localStorage) => async (config: AxiosRequestConfig) => {
  const token = storage.getItem('token');
  return { ...config, headers: { ...config.headers, 'x-access-token': token } };
};

type ConfigureAxiosInstancesProps = { storage: typeof localStorage };

const configureAxiosInstances = ({ storage }: ConfigureAxiosInstancesProps) => {
  const axiosGateway = axios.create({ baseURL: selectedBackend.GATEWAY_URL, timeout: 0 });
  const instance = axios.create({ baseURL: selectedBackend.BFF_URL, timeout: 30000 });
  const customInstance = axios.create({ baseURL: selectedBackend.ANALYTICS_URL, timeout: 30000 });

  axiosGateway.interceptors.request.use(addAuthTokenToRequest(storage));
  instance.interceptors.request.use(addAuthTokenToRequest(storage));
  customInstance.interceptors.request.use(addAuthTokenToRequest(storage));

  return { instance, axiosGateway, customInstance };
};

export const { instance, axiosGateway, customInstance } = configureAxiosInstances({ storage: localStorage });
