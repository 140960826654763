import ReactPlayer from 'react-player';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-around;
`;

export const Group = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Video = styled(ReactPlayer)``;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray800};
  text-transform: uppercase;
  width: 100%;
  padding-bottom: 1em;
`;

export const Box = styled.div`
  background-color: ${({ theme }) => theme.gray50};
  padding: 1em;
  margin-bottom: 1.5em;
  border-radius: 8px;
`;
