import Chart from 'react-apexcharts';
import { options, series } from './mock';

interface LineChartProps {
  name?: string;
  id?: string;
  data: number[];
  categories?: string[] | number[];
}
export const LineChart = ({ name, id, data, categories }: LineChartProps) => {
  return (
    <Chart
      style={{ marginTop: 'auto' }}
      type="area"
      options={options({ id, categories })}
      series={series({ name, data })}
      height={350}
    />
  );
};
