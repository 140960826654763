import { BiCameraHome, BiChat } from 'react-icons/bi';
import { FiAlertTriangle } from 'react-icons/fi';
import { FaHeadset } from 'react-icons/fa';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 256px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Section = styled.section`
  width: 100%;
  position: relative;
`;

export const TitleSection = styled.h2`
  color: ${({ theme }) => theme.gray800};
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 600;
  margin: 1rem 0;
  padding: 0 2rem;
`;

export const SectionLink = styled.div<{ match: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 3rem;
  transition-duration: 300ms;
  border-radius: 8px;
  color: ${({ theme }) => theme.gray800};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.gray50};
  }
  &::before {
    content: '';
    height: 40px;
    width: 5px;
    position: absolute;
    background-color: transparent;
    left: 0;
    border-radius: 0 4px 4px 0;
    transition-duration: 300ms;
  }
  ${({ match }) =>
    match &&
    css`
      color: ${({ theme }) => theme.primary};
      &::before {
        background-color: ${({ theme }) => theme.primary};
      }
    `}
`;

export const LinkName = styled.p`
  font-size: 16px;
  margin-left: 1rem;
`;

export const CameraIcon = styled(BiCameraHome)`
  font-size: 16px;
`;

export const AlertIcon = styled(FiAlertTriangle)`
  font-size: 16px;
`;

export const ChatIcon = styled(BiChat)`
  font-size: 16px;
`;

export const AttendanceIcon = styled(FaHeadset)`
  font-size: 16px;
`;

export const Logout = styled.button`
  margin-top: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  background-color: #f45;
  border-radius: 8px;
  border: none;
  padding: 1rem 2rem;
  color: #ffffff;
  transition-duration: 300ms;
  &:hover {
    cursor: pointer;
    background-color: #f46;
  }
`;
