import { FormEvent, useCallback, useRef } from 'react';
import { BaseInputProps, Form, PrimaryButton, PrimaryInput } from '../../components';
import { useUser } from '../../hook';
import * as S from './styles';

export const Login = () => {
  const { login, loading } = useUser();
  const emailRef = useRef<BaseInputProps>(null);
  const passwordRef = useRef<BaseInputProps>(null);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (emailRef.current?.value && passwordRef.current?.value) {
        await login({ email: emailRef.current.value, password: passwordRef.current.value });
      }
    },
    [login]
  );

  return (
    <S.Container>
      <S.Content>
        <Form onSubmit={handleSubmit}>
          <S.Title>Login</S.Title>
          <S.Label>Email</S.Label>
          <PrimaryInput
            name="email"
            ref={emailRef}
            outlined
            width={380}
            type="email"
            placeholder="Digite seu email aqui"
          />
          <S.Label>Senha</S.Label>
          <PrimaryInput
            name="password"
            ref={passwordRef}
            outlined
            width={380}
            type="password"
            placeholder="Digite sua senha aqui"
          />
          <PrimaryButton label="Entrar" isLoading={loading} type="submit" width={380} />
        </Form>
      </S.Content>
      {/* <S.Container>
        <S.Image src={lightImage} />
      </S.Container> */}
    </S.Container>
  );
};
