export const ALERT_IDENTIFIERS = {
  fire: 'fire',
  flood: 'flood',
  treeFall: 'treeFall',
  accident: 'accident',
  irregularWasteDisposal: 'irregularWasteDisposal',
  irregularSidewalk: 'irregularSidewalk',
  lackOfLight: 'lackOfLight',
  holeInTheRoad: 'holeInTheRoad',
  irregularOccupation: 'irregularOccupation',
  irregularCommerce: 'irregularCommerce',
  vandalism: 'vandalism',
  dengueBreeding: 'dengueBreeding',
  theft: 'theft',
  murder: 'murder',
  dangerousDriving: 'dangerousDriving',
  drugTrafficking: 'drugTrafficking',
  kidnapping: 'kidnapping',
  violenceAgainstWomen: 'violenceAgainstWomen',
  sexualCrime: 'sexualCrime',
  shooting: 'shooting',
  animalAbuse: 'animalAbuse',
  fight: 'fight',
  homeInvasion: 'homeInvasion',
  fugitive: 'fugitive',
  disturbanceOfPublicOrder: 'disturbanceOfPublicOrder',
  hateCrime: 'hateCrime',
  disappearance: 'disappearance',
  others: 'others',
  deleted: 'deleted',
} as const;

export const ALERT_IMAGES = {
  theft: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/roubo.png',
  murder: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/homicidio.png',
  dangerousDriving: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/direcao.png',
  drugTrafficking: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/trafico.png',
  kidnapping: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/sequestro.png',
  violenceAgainstWomen: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/penha.png',
  sexualCrime: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/sexual.png',
  shooting: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/tiroteio.png',
  animalAbuse: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/animais.png',
  fight: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/briga.png',
  homeInvasion: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/invasao.png',
  fugitive: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/foragido.png',
  disturbanceOfPublicOrder: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/orderPublica.png',
  hateCrime: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/odio.png',
  fire: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/incendio.png',
  flood: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/alagamento.png',
  treeFall: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/arvore.png',
  accident: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/acidente.png',
  irregularWasteDisposal: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/lixo.png',
  irregularSidewalk: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/calcada.png',
  lackOfLight: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/luz.png',
  holeInTheRoad: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/buraco.png',
  irregularOccupation: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/ocupacaoIrregular.png',
  irregularCommerce: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/comercio.png',
  vandalism: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/vandalismo.png',
  others: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/others.png',
  dengueBreeding: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/dengue.png',
  disappearance: 'https://yc-alert-type-images.s3-sa-east-1.amazonaws.com/desaparecimento.png',
} as const;

export const ALERT_TYPE_DICTIONARY = {
  [ALERT_IDENTIFIERS.disappearance]: 'Desaparecimento',
  [ALERT_IDENTIFIERS.dangerousDriving]: 'Direção perigosa',
  [ALERT_IDENTIFIERS.drugTrafficking]: 'Tráfico de Drogas',
  [ALERT_IDENTIFIERS.kidnapping]: 'Sequestro',
  [ALERT_IDENTIFIERS.violenceAgainstWomen]: 'Maria da Penha',
  [ALERT_IDENTIFIERS.sexualCrime]: 'Crime sexual',
  [ALERT_IDENTIFIERS.shooting]: 'Tiroteio',
  [ALERT_IDENTIFIERS.animalAbuse]: 'Maus tratos animais',
  [ALERT_IDENTIFIERS.theft]: 'Roubo',
  [ALERT_IDENTIFIERS.fight]: 'Briga',
  [ALERT_IDENTIFIERS.homeInvasion]: 'Invasão de domicílio',
  [ALERT_IDENTIFIERS.fugitive]: 'Foragido',
  [ALERT_IDENTIFIERS.disturbanceOfPublicOrder]: 'Perturbação da ordem pública',
  [ALERT_IDENTIFIERS.hateCrime]: 'Crime de ódio',
  [ALERT_IDENTIFIERS.fire]: 'Incêndio',
  [ALERT_IDENTIFIERS.flood]: 'Alagamento',
  [ALERT_IDENTIFIERS.treeFall]: 'Queda de árvore',
  [ALERT_IDENTIFIERS.accident]: 'Acidente',
  [ALERT_IDENTIFIERS.irregularWasteDisposal]: 'Descarte irregular de lixo',
  [ALERT_IDENTIFIERS.irregularSidewalk]: 'Calçada irregular',
  [ALERT_IDENTIFIERS.lackOfLight]: 'Falta de luz',
  [ALERT_IDENTIFIERS.holeInTheRoad]: 'Buraco na via',
  [ALERT_IDENTIFIERS.irregularOccupation]: 'Ocupação irregular',
  [ALERT_IDENTIFIERS.irregularCommerce]: 'Comércio irregular',
  [ALERT_IDENTIFIERS.vandalism]: 'Vandalismo',
  [ALERT_IDENTIFIERS.dengueBreeding]: 'Criadouro de dengue',
  [ALERT_IDENTIFIERS.murder]: 'Homicídio',
  [ALERT_IDENTIFIERS.others]: 'Outros',
} as const;

export const ALERT_CATEGORIES_IDENTIFIERS = {
  urbanIssue: 'urbanIssue',
  crime: 'crime',
  others: 'others',
} as const;

export const ALERT_CATEGORIES_DICTIONARY = {
  [ALERT_CATEGORIES_IDENTIFIERS.urbanIssue]: 'Zeladoria',
  [ALERT_CATEGORIES_IDENTIFIERS.crime]: 'Criminalidade',
  [ALERT_CATEGORIES_IDENTIFIERS.others]: 'Outros',
} as const;

export const ALERT_CATEGORIES = ['urbanIssue', 'crime', 'others'] as const;
