import { Route } from 'react-router-dom';
import { Header, NavBar } from '../../components';
import { AlertScreen, TimeScreen, HomeScreen, TypeDetailScreen, CategoryDetailScreen } from '../../pages';
import CameraScreen from '../../pages/Camera';
import ListCamerasScreen from '../../pages/ListCamera';
import * as S from './styles';

export const Private = () => {
  return (
    <S.Container>
      <Header />
      <S.Content>
        <NavBar />
        <Route path="/" exact component={HomeScreen} />
        <Route path="/alert/metrics" exact component={AlertScreen} />
        {/* <Route path="/attendance/time" exact component={TimeScreen} /> */}
        <Route path="/attendance/messages" exact component={TimeScreen} />
        <Route path="/cameras" exact component={ListCamerasScreen} />
        <Route path="/cameras/:id" exact component={CameraScreen} />
        <Route path="/alert/types/:type" exact component={TypeDetailScreen} />
        <Route path="/alert/categories/:category" exact component={CategoryDetailScreen} />
      </S.Content>
    </S.Container>
  );
};
