import React, { MouseEventHandler, ReactEventHandler } from 'react';
import { ALERT_CATEGORIES } from '../../domain/alert-types';
import * as S from './styles';

interface ListProps {
  type: string;
  renderItem: (data: any) => JSX.Element;
  data: any[];
  onClickItem?: (data: { event: ReactEventHandler<HTMLLIElement>; item: any; index: number }) => void;
  showTotal?: boolean;
}

export const List = ({ type, renderItem: RenderItem, data, onClickItem, showTotal = true }: ListProps) => {
  return (
    <S.Container>
      <S.HeaderList>
        <S.TitleHeader>Itens</S.TitleHeader>
        {showTotal && <S.TitleHeader>total de {type}</S.TitleHeader>}
      </S.HeaderList>
      <S.List>
        {data.map((item, index) => {
          const handleClickItem = onClickItem
            ? (event: ReactEventHandler<HTMLLIElement>) => onClickItem({ event, item, index })
            : undefined;
          return <RenderItem onClick={handleClickItem} {...item} key={`${type}-${item?.key ?? index}`} index={index} />;
        })}
      </S.List>
    </S.Container>
  );
};
interface ListItemProps {
  onClick: MouseEventHandler<HTMLLIElement>;
  title: string;
  index: number;
  quantity: number;
}
const CameraItem = ({
  title,
  name,
  quantity,
  image,
  onClick,
}: {
  name: string;
  image: string;
} & ListItemProps) => {
  const handleClick: MouseEventHandler<HTMLLIElement> = event => {
    onClick?.(event);
  };
  return (
    <S.ListItem onClick={handleClick} hasOnClick={!!onClick}>
      <S.Image src={image} />
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{name}</S.Description>
      </S.Wrapper>
      <S.Quantity>{quantity}</S.Quantity>
    </S.ListItem>
  );
};

const AlertItem = ({
  title,
  address,
  quantity,
  image,
  onClick,
}: {
  address: string;
  image: string;
} & ListItemProps) => {
  const handleClick: MouseEventHandler<HTMLLIElement> = event => {
    onClick?.(event);
  };
  return (
    <S.ListItem onClick={handleClick} hasOnClick={!!onClick}>
      <S.Image src={image} />
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{address}</S.Description>
      </S.Wrapper>
      <S.Quantity>{quantity}</S.Quantity>
    </S.ListItem>
  );
};

const AlertTypeItem = ({
  description,
  alertLink,
  image,
  status,
  onClick,
}: {
  description: string;
  image: string;
  alertLink: string;
  status: string;
} & ListItemProps) => {
  const handleClick: MouseEventHandler<HTMLLIElement> = event => {
    onClick?.(event);
  };

  function getTranslatedStatus(s: string): string {
    switch (s) {
      case 'PENDING':
        return 'Pendente';
      case 'ANALYSIS':
        return 'Em atendimento';
      case 'CLOSED':
        return 'Finalizado';
      case 'IN_CONTENT_MODERATION':
        return 'Em moderação';
      case 'REJECTED_BY_MODERATION':
        return 'Rejeitado pela moderação';
      default:
        return status;
    }
  }

  return (
    <S.ListItem onClick={handleClick} hasOnClick={!!onClick}>
      <S.Image src={image} />
      <S.Wrapper>
        <S.Title>{description}</S.Title>
        <S.Description>{getTranslatedStatus(status)}</S.Description>
      </S.Wrapper>
      <S.Link target="_blank" href={alertLink}>
        Abrir no pelotão
      </S.Link>
    </S.ListItem>
  );
};

const RankingItem = ({
  title,
  name,
  quantity,
  index,
  onClick,
}: {
  name: string;
  image: string;
} & ListItemProps) => {
  const handleClick: MouseEventHandler<HTMLLIElement> = event => {
    onClick?.(event);
  };
  return (
    <S.ListItem onClick={handleClick} hasOnClick={!!onClick}>
      <S.Crown position={index} />
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{name}</S.Description>
      </S.Wrapper>
      <S.Quantity>{quantity}</S.Quantity>
    </S.ListItem>
  );
};

const CategoryItem = ({
  title,
  name,
  quantity,
  category,
  onClick,
}: {
  name: string;
  image: string;
  category: typeof ALERT_CATEGORIES[number];
} & ListItemProps) => {
  const handleClick: MouseEventHandler<HTMLLIElement> = event => {
    onClick?.(event);
  };
  return (
    <S.ListItem onClick={handleClick} hasOnClick={!!onClick}>
      <S.Category category={category} />
      <S.Wrapper>
        <S.Title>{title}</S.Title>
        <S.Description>{name}</S.Description>
      </S.Wrapper>
      <S.Quantity>{quantity}</S.Quantity>
    </S.ListItem>
  );
};

List.CameraItem = CameraItem;
List.AlertItem = AlertItem;
List.RankingItem = RankingItem;
List.CategoryItem = CategoryItem;
List.AlertTypeItem = AlertTypeItem;

export * from './mocks';
