import { instance, customInstance } from '.'

export const instancePost = async <S>(url: string, data: unknown) => {
  return await instance.post<S>(url, data)
}
export const instanceGet = async <S>(url: string) => {
  return await instance.get<S>(url)
}
export const customInstanceGet = async <S>(url: string) => {
  return await customInstance.get<S>(url)
}
