import CountUp from 'react-countup';
import styled from 'styled-components';
import { IColors } from '../../assets/Theme';

export const Container = styled.div<{ force: number; margin: string }>`
  flex: ${({ force }) => force};
  height: 150px;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  margin: ${({ margin }) => margin};
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.gray800};
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const Content = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.gray50};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
`;

export const Value = styled(CountUp)<{ color?: IColors }>`
  color: ${({ theme, color }) => theme[color ?? 'gray800']};
  font-size: 48px;
  font-weight: 600;
`;

export const ContainerDisplay = styled.div`
  /* flex: 1; */
  /* max-height: fit-content; */
  width: 100%;
  margin-top: 1rem;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
`;
