import { createReState } from '@raulpesilva/re-state';

type Camera = {
  id: number;
  name: string;
  disabled?: boolean;
};

export const useCamerasState = createReState<Camera[]>('cameras', []);

type Metrics = {
  classTypeDescription: string;
  classTypeId: number;
  count: number;
  items: any;
};

export const useMetricsState = createReState<Metrics[]>('metrics', []);
