export const colors = {
  primary: '#FDDA2A',
  secondary: '#147AD6',
  background: '#ffffff',
  gray50: '#F7FAFC',
  gray100: '#EDF2F7',
  gray400: '#A0AEC0',
  gray500: '#7982A6',
  gray800: '#2D3748',
  blue400: '#4299E1',
  urbanIssue: '#147AD6',
  crime: '#FF9100',
  others: '#485365',
} as const;
export const colorsDark = {
  primary: '#FDDA2A',
  secondary: '#147AD6',
  background: '#000000',
  gray50: '#2D3748',
  gray100: '#7982A6',
  gray500: '#EDF2F7',
  gray800: '#F7FAFC',
  blue400: '#4299E1',
  urbanIssue: '#147AD6',
  crime: '#FF9100',
  others: '#485365',
};

const light = { ...colors };
const dark = { ...colorsDark };
export const theme = { light, dark };
export type IColors = keyof typeof colors;
