import ReactPlayer from 'react-player';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Video = styled(ReactPlayer)``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray800};
  text-transform: uppercase;
  width: 100%;
`;

export const Box = styled.div`
  background-color: ${({ theme }) => theme.gray50};
  padding: 1em;
  margin-bottom: 1.5em;
  border-radius: 8px;
`;
