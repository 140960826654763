import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components';
import { useCameras } from '../../hook';
import * as S from './styled';

const ListCamerasScreen = () => {
  const { list, cameras } = useCameras();
  const [play, setPlay] = useState(false);
  const history = useHistory();

  useEffect(() => {
    list();
    setTimeout(() => {
      setPlay(!play);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      {cameras.map((item, index) => (
        <S.Box>
          {!item.disabled && (
            <S.Header>
              <S.Title>{item.name}</S.Title>
              <PrimaryButton label="Detalhes" onClick={() => history.push(`/cameras/${item.id}`)} />
            </S.Header>
          )}
          {!item.disabled && (
            <div style={{ width: '38vw' }}>
              <S.Video
                key={index}
                url={`https://a04.yellowcam.com/live/${item.name}.stream/playlist.m3u8?DVR`}
                width="100%"
                height="100%"
                controls
              />
            </div>
          )}
        </S.Box>
      ))}
    </S.Container>
  );
};

export default ListCamerasScreen;
