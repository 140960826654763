import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useLogin } from '../../hook';
import * as S from './styles';

export const NavBar = () => {
  const { logout } = useLogin();
  return (
    <S.Container>
      <S.Section>
        <S.TitleSection>Camera</S.TitleSection>
        <NavBarButton path="/" name="Métricas" Icon={S.CameraIcon} />
        <NavBarButton path="/cameras" name="Deteção de objetos" Icon={S.CameraIcon} />
      </S.Section>
      <S.Section>
        <S.TitleSection>Alerta</S.TitleSection>
        <NavBarButton path="/alert/metrics" name="Métricas" Icon={S.AlertIcon} />
      </S.Section>
      <S.Section>
        <S.TitleSection>Atendimento</S.TitleSection>
        {/* <NavBarButton path="/attendance/time" name="tempo" Icon={S.AttendanceIcon} /> */}
        <NavBarButton path="/attendance/messages" name="Mensagens" Icon={S.ChatIcon} />
      </S.Section>
      <S.Logout onClick={logout}>Sair da conta</S.Logout>
    </S.Container>
  );
};

const NavBarButton = ({ path, name, Icon }: { path: string; name: string; Icon: React.ElementType }) => {
  const history = useHistory();

  const handleNavigate = (route: string) => () => {
    history.push(route);
  };
  const match = useRouteMatch({
    exact: true,
    path: path,
  });

  return (
    <S.SectionLink onClick={handleNavigate(path)} match={!!match}>
      <Icon />
      <S.LinkName>{name}</S.LinkName>
    </S.SectionLink>
  );
};
