import useSWF from '@raulpesilva/swf';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { customInstanceGet } from '../../api/utils';
import { Box, Display, LineChart, List } from '../../components';
import { ALERT_CATEGORIES_DICTIONARY, ALERT_IMAGES, ALERT_TYPE_DICTIONARY } from '../../domain/alert-types';
import * as S from './styles';

type ByCategory = { _id: string; count: number }[];
type ByPopulars = { _id: string; count: number }[];
type ByMonth = { _id: { year: number; month: number }; count: number }[];
type ClickAlert = { event: React.ReactEventHandler<HTMLLIElement>; item: any; index: number };
interface Alerts {
  byRegion: any[];
  metrics: [
    {
      byCategory: ByCategory;
      byMonth: ByMonth;
      inCameras: [{ amount: number }];
      populars: ByPopulars;
      total: [{ amount: number }];
    }
  ];
}

const sortByMont = (arr: ByMonth) => {
  return arr.sort((a, b) => {
    const formattedA = `${a._id.year}${String(a._id.month).padStart(2, '0')}`;
    const formattedB = `${b._id.year}${String(b._id.month).padStart(2, '0')}`;
    if (formattedA > formattedB) return -1;
    return 1;
  });
};
const createDateString = (alert: ByMonth[0]) => new Date(alert._id.year, alert._id.month - 1, 1).toISOString();
const formatAlertCategory = (category: ByCategory[0]) => ({
  title: ALERT_CATEGORIES_DICTIONARY[category._id as keyof typeof ALERT_CATEGORIES_DICTIONARY],
  quantity: category.count,
  category: category._id,
});
const formatAlertPopular = (alert: ByPopulars[0]) => ({
  id: alert._id,
  title: ALERT_TYPE_DICTIONARY[alert._id as keyof typeof ALERT_TYPE_DICTIONARY],
  quantity: alert.count,
  image: ALERT_IMAGES[alert._id as keyof typeof ALERT_TYPE_DICTIONARY],
});

export const AlertScreen = () => {
  const history = useHistory();
  const { loading, result, send } = useSWF<{ data: Alerts }>('/alerts', customInstanceGet);
  const [total, setTotal] = useState(0);
  const [inCameras, setInCameras] = useState(0);
  const [byCategory, setByCategory] = useState<ByCategory>([]);
  const [byMonth, setByMonth] = useState<ByMonth>([]);
  const [byPopulars, setByPopulars] = useState<ByPopulars>([]);

  useEffect(() => {
    const metrics = result?.data?.metrics?.[0];
    if (metrics?.total[0].amount) setTotal(metrics.total[0].amount);
    if (metrics?.inCameras[0].amount) setInCameras(metrics.inCameras[0].amount);
    if (metrics?.byCategory) setByCategory(metrics.byCategory);
    if (metrics?.byMonth) setByMonth(sortByMont(metrics.byMonth));
    if (metrics?.populars) setByPopulars(metrics.populars);
  }, [result]);

  useEffect(() => {
    send({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertsPopular = byPopulars.map(formatAlertPopular);
  const alertChartData = byMonth.map(alert => alert.count);
  const alertChartSeries = byMonth.map(createDateString);
  const alertsCategory = useMemo(() => byCategory.map(formatAlertCategory), [byCategory]);
  const urbanIssueCount = useMemo(() => byCategory?.[0]?.count ?? 0, [byCategory]);
  const crimeCount = useMemo(() => byCategory?.[1]?.count ?? 0, [byCategory]);
  const othersCount = useMemo(() => byCategory?.[2]?.count ?? 0, [byCategory]);

  const handleClickAlert = ({ item }: ClickAlert) => history.push(`/alert/types/${item.id}`);
  const handleClickCategory = ({ item }: ClickAlert) => history.push(`/alert/categories/${item.category}`);

  if (loading) return <div>loading</div>;

  return (
    <S.Container>
      <S.Title>Totais</S.Title>
      <Display.Container>
        <Display title="alertas" value={total} />
        <Display title="alertas em cameras" value={inCameras} color="primary" />
        {/* <Display title="em cameras restritas" value={alertMetrics.total[0].amount} /> */}
        <Display title="zeladoria" value={urbanIssueCount} color="urbanIssue" />
        <Display title="criminalidade" value={crimeCount} color="crime" />
        <Display title="outros" value={othersCount} color="others" />
      </Display.Container>
      <Box.Container>
        <Box>
          <Box.Title>Top 5 alertas mais populares</Box.Title>
          <List
            type="alertas"
            onClickItem={data => handleClickAlert(data)}
            data={alertsPopular.slice(0, 5)}
            renderItem={List.AlertItem}
          />
        </Box>
        <Box>
          <Box.Title>quantidade de alertas por mês</Box.Title>
          <LineChart name="Quantidade de alertas" id="alerts" categories={alertChartSeries} data={alertChartData} />
        </Box>
        <Box>
          <Box.Title>quantidade total de alertas por tipo</Box.Title>
          <S.LimiterList>
            <List
              type="alertas"
              onClickItem={data => handleClickAlert(data)}
              data={alertsPopular}
              renderItem={List.AlertItem}
            />
          </S.LimiterList>
        </Box>
        <Box fit>
          <Box.Title>quantidade de alertas por categoria</Box.Title>
          {alertsCategory.length > 0 && (
            <List
              type="alertas"
              onClickItem={handleClickCategory}
              data={alertsCategory}
              renderItem={List.CategoryItem}
            />
          )}
        </Box>
      </Box.Container>
    </S.Container>
  );
};
