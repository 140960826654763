import { BsGearFill } from 'react-icons/bs';
import styled from 'styled-components';

export const Container = styled.header`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 22px;
`;

export const Logo = styled.h1`
  font-size: xx-large;
  font-weight: 700;
  color: ${({ theme }) => theme.primary};
`;

export const Search = styled.input`
  width: 50%;
  height: 40px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.gray50};
  border: none;
  padding: 0 1rem;
  outline-color: ${({ theme }) => theme.gray100};
`;

export const WrapperUser = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const UserName = styled.p`
  font-size: large;
  font-weight: 600;
  color: ${({ theme }) => theme.gray800};
`;

export const GearIcon = styled(BsGearFill)`
  font-size: 20px;
  color: ${({ theme }) => theme.gray800};
  &:hover {
    cursor: pointer;
  }
`;
