import useSWF from '@raulpesilva/swf';
import { useCallback } from 'react';
import { customInstanceGet } from '../../api/utils';
import { useCamerasState, useMetricsState } from '../../states/useCameraState';

export const useCameras = (id?: number) => {
  const { loading, send, error } = useSWF<any>('/sources', customInstanceGet);
  const { send: getMetrics } = useSWF<any>(`/info/${id}`, customInstanceGet);

  const [cameras, setCameras] = useCamerasState();
  const [metrics, setMetrics] = useMetricsState();

  const list = useCallback(async () => {
    try {
      const { result } = await send({ noCache: true });
      const getName = (url: string) => {
        const text = url.split('//')[1];
        return text.split('.ddns')[0];
      };
      if (result?.data) {
        setCameras([
          ...result?.data?.map((i: any) => ({ id: i.id, name: getName(i.connectionURL) })),
          { disabled: true },
        ]);
      }
    } catch {}
  }, [send, setCameras]);

  const getData = useCallback(async () => {
    try {
      const { result } = await getMetrics({ noCache: true });
      if (result?.data) {
        setMetrics(result?.data);
      }
    } catch {}
  }, [getMetrics, setMetrics]);

  return { list, metrics, cameras, getData, loading, error };
};
