import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Display } from '../../components';
import { useCameras } from '../../hook';
import * as S from './styled';

const NAMES: any = {
  Car: 'Carros',
  Person: 'Pessoas',
  Truck: 'Caminhões',
  Bike: 'Motocicletas',
};

const CameraScreen = () => {
  const { id }: any = useParams();
  const { cameras, list, getData, metrics } = useCameras(id);
  const data: any = cameras.find(i => i.id === id);

  useEffect(() => {
    if (!cameras.length) {
      list();
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.Box>
        <S.Title>{data?.name}</S.Title>
        {data?.name && (
          <div style={{ width: '60vw' }}>
            <S.Video
              url={`https://a04.yellowcam.com/live/${data?.name}.stream/playlist.m3u8?DVR`}
              width="100%"
              height="100%"
              controls
              playing
            />
          </div>
        )}
      </S.Box>
      <S.Group>
        {metrics?.map((item, index) => {
          return <Display key={index} title={NAMES[item.classTypeDescription]} value={item.count} margin=".5em" />;
        })}
      </S.Group>
    </S.Container>
  );
};

export default CameraScreen;
