import React, { FormEvent } from 'react';
import * as S from './styles';
import { BaseFormProps } from './types';

export const Form: React.FC<BaseFormProps> = ({ children, onSubmit, ...rest }) => {
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit && onSubmit(event);
  };

  return (
    <S.Container {...rest} onSubmit={handleSubmit}>
      <S.Highlight1 />
      <S.Highlight2 />
      {children}
    </S.Container>
  );
};
