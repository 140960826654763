import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  padding: 0 20px;
`;

export const Title = styled.h3`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray800};
  text-transform: uppercase;
`;

export const WrapperHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  flex-wrap: wrap;
`;

export const SectionHeader = styled.div`
  flex: 1;
  min-width: 420px;
`;
