import useSWF from '@raulpesilva/swf';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { customInstanceGet } from '../../api/utils';
import { Box, Display, List } from '../../components';
import { ALERT_CATEGORIES, ALERT_IMAGES, ALERT_TYPE_DICTIONARY } from '../../domain';
import * as S from './styles';

type AlertTypes = keyof typeof ALERT_TYPE_DICTIONARY;
type Alert = {
  _id: string;
  category: typeof ALERT_CATEGORIES[number];
  type: AlertTypes;
  description: string;
  status: string;
};
type Alerts = { status: string; count: number; items: Array<Alert> }[];
interface SWF {
  data: Alerts;
}
const formatAlertCategory = (alerts: Alert[]) =>
  alerts.map(a => ({
    image: ALERT_IMAGES[a.type],
    status: a.status,
    title: ALERT_TYPE_DICTIONARY[a.type],
    description: a.description,
    alertLink: `https://beta-pelotao.yellowcam.com/alertas/alerta/${a._id}`,
  }));

export const TypeDetailScreen = () => {
  const history = useHistory();
  let { type } = useParams<{ type: AlertTypes }>();
  const { loading, result, send } = useSWF<SWF>(`/alerts/drill-down?key=type&value=${type}`, customInstanceGet);

  useEffect(() => {
    send({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(result);
  }, [result]);

  if (loading && !result?.data) return <span>...loading</span>;

  const closedInfo = result?.data?.find(d => d.status === 'CLOSED');
  const pendingInfo = result?.data?.find(d => d.status === 'PENDING');
  const analysisInfo = result?.data?.find(d => d.status === 'ANALYSIS');
  const handleBack = () => {
    history.push('/alert/metrics');
  };
  return (
    <S.Container>
      {loading && <span>recarregando</span>}
      <S.BackButton onClick={handleBack}>voltar</S.BackButton>
      <Display.Container>
        <Display title="Abertos" value={analysisInfo?.count || 0} />
        <Display title="Em atendimento" value={pendingInfo?.count || 0} />
        <Display title="Finalizados" value={closedInfo?.count || 0} />
      </Display.Container>

      <Box style={{ marginTop: 20, marginBottom: 20 }} fit>
        {/* <Box.Title></Box.Title> */}
        <List
          showTotal={false}
          type={'teste'}
          renderItem={List.AlertTypeItem}
          data={formatAlertCategory(result?.data.flatMap(d => d.items) ?? [])}
        />
        {/* {result?.data?.map(i => (
          <div key={i._id} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
            <img style={{ width: 50, borderRadius: 8 }} src={ALERT_IMAGES[i.type]} alt={i.type} />
            <span style={{ marginLeft: 10 }}>{ALERT_TYPE_DICTIONARY[i.type]}</span>
            <span style={{ marginLeft: 'auto', cursor: 'pointer' }}>Abrir no pelotao</span>
          </div>
        ))} */}
      </Box>
    </S.Container>
  );
};
