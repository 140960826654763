import { Box, Display, LineChart, List } from '../../components';
import cameraMetrics from '../../mocks/camera-metrics.json';
import * as S from './styles';

export const HomeScreen = () => {
  const totalAlerts = cameraMetrics.populars.reduce((acc, i) => acc + i.alerts, 0);
  const camerasAlert = cameraMetrics.populars.map(camera => ({
    id: camera._id,
    title: camera.sponsor,
    name: camera.name,
    quantity: camera.alerts,
    image: camera.sponsorLogoUrl,
  }));

  const sponsorAlert = cameraMetrics.bySponsor.map(sponsor => ({
    id: sponsor._id,
    title: sponsor._id,
    quantity: sponsor.count,
  }));

  return (
    <S.Container>
      <S.Title>Totais</S.Title>
      <Display.Container>
        <Display title="cameras" value={cameraMetrics.total[0].amount} />
        <Display title="cameras publicas" value={cameraMetrics.public[0].amount} />
        <Display title="cameras restritas" value={cameraMetrics.restricted[0].amount} />
        <Display title="alertas em cameras" value={totalAlerts} color="primary" />
      </Display.Container>
      <Box.Container>
        <Box>
          <Box.Title>Top 5 cameras com mais alertas</Box.Title>
          <List type="alertas" data={camerasAlert.slice(0, 5)} renderItem={List.CameraItem} />
        </Box>
        <Box>
          <Box.Title>Total de cameras instaladas na ultima semana</Box.Title>
          <LineChart name="Quantidade de cameras" id="cameras" data={[10, 40, 35, 60, 45, 60, 70]} />
        </Box>
        <Box>
          <Box.Title>Cameras com mais alertas</Box.Title>
          <S.LimiterList>
            <List type="alertas" data={camerasAlert} renderItem={List.CameraItem} />
          </S.LimiterList>
        </Box>
        <Box fit>
          <Box.Title>patrocinadores com mais cameras</Box.Title>
          <List type="cameras" data={sponsorAlert} renderItem={List.RankingItem} />
        </Box>
      </Box.Container>
    </S.Container>
  );
};
