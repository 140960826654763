import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
`;

export const Content = styled.main`
  display: flex;
  flex: 1;
  max-height: calc(100% - 80px);
`;
