import { ApexOptions } from 'apexcharts';
import { theme } from '../../assets/Theme';

export const series = ({
  name = 'Quantidade',
  data = [10, 40, 35, 60, 45, 60, 70],
}: {
  name?: string;
  data?: number[];
}): ApexAxisChartSeries | ApexNonAxisChartSeries => [
  {
    name: name,
    data: data,
  },
];

const defaultCategories = [
  '2021-03-13T00:00:00.000Z',
  '2021-03-14T00:00:00.000Z',
  '2021-03-15T00:00:00.000Z',
  '2021-03-16T00:00:00.000Z',
  '2021-03-17T00:00:00.000Z',
  '2021-03-18T00:00:00.000Z',
  '2021-03-19T00:00:00.000Z',
];

export const options = ({
  id = 'Chart',
  categories = defaultCategories,
}: {
  id?: string;
  categories?: string[] | number[];
}): ApexOptions => ({
  chart: {
    id: id,
    zoom: { enabled: false },
    foreColor: theme.light.gray500,
  },
  grid: { show: false },
  dataLabels: { enabled: false },
  xaxis: {
    type: 'datetime',
    categories: categories,
  },
  fill: { opacity: 0.3, type: 'gradient', gradient: { shade: 'light', opacityFrom: 0.7, opacityTo: 0.3 } },
});
