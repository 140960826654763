import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.gray50};
  padding: 20px;
  border-radius: 8px;
  flex: 1;
  min-height: 400px;
  min-width: max(calc((100vw - 256px) / 2 - 20px), 420px);
  display: flex;
  flex-direction: column;
  ${({ fit }: { fit?: boolean }) =>
    fit &&
    css`
      height: fit-content;
      max-height: fit-content;
      min-height: fit-content;
    `}
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${({ theme }) => theme.gray800};
  text-transform: uppercase;
`;

export const BoxContainer = styled.div`
  flex: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
`;
