import { useUserSelect } from '../../states';
import { Avatar } from '../Avatar';
import * as S from './styles';

export const Header = () => {
  const user = useUserSelect();
  return (
    <S.Container>
      <S.Logo>Analytics</S.Logo>
      {/* <S.Search placeholder="Digite sua pesquisa" /> */}
      <S.WrapperUser>
        {/* <S.GearIcon /> */}
        <S.UserName>{user.name}</S.UserName>
        <Avatar src="https://yc-avatares.s3-sa-east-1.amazonaws.com/menino-loiro.png" />
      </S.WrapperUser>
    </S.Container>
  );
};
