import React, { ReactNode } from 'react';
import * as S from './styles';

interface BoxContentProps {
  children: React.ReactNode;
  fit?: boolean;
  style?: any;
}

export const Box = ({ children, fit, style }: BoxContentProps) => {
  return (
    <S.Container style={style} fit={fit}>
      {children}
    </S.Container>
  );
};

const Title = ({ children }: { children: ReactNode }) => {
  return <S.Title>{children}</S.Title>;
};

const Container = ({ children }: { children: React.ReactNode }) => {
  return <S.BoxContainer>{children}</S.BoxContainer>;
};

Box.Title = Title;
Box.Container = Container;
